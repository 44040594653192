// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-couple-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/contact/couple.js" /* webpackChunkName: "component---src-pages-contact-couple-js" */),
  "component---src-pages-contact-hello-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/contact/hello.js" /* webpackChunkName: "component---src-pages-contact-hello-js" */),
  "component---src-pages-contact-index-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-planner-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/contact/planner.js" /* webpackChunkName: "component---src-pages-contact-planner-js" */),
  "component---src-pages-contact-portraits-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/contact/portraits.js" /* webpackChunkName: "component---src-pages-contact-portraits-js" */),
  "component---src-pages-experience-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-faq-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/investment.js" /* webpackChunkName: "component---src-pages-investment-js" */),
  "component---src-pages-portfolio-engagements-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/portfolio/engagements.js" /* webpackChunkName: "component---src-pages-portfolio-engagements-js" */),
  "component---src-pages-portfolio-index-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-portraits-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/portfolio/portraits.js" /* webpackChunkName: "component---src-pages-portfolio-portraits-js" */),
  "component---src-pages-portfolio-travel-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/portfolio/travel.js" /* webpackChunkName: "component---src-pages-portfolio-travel-js" */),
  "component---src-pages-portfolio-weddings-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/portfolio/weddings.js" /* webpackChunkName: "component---src-pages-portfolio-weddings-js" */),
  "component---src-pages-shop-js": () => import("/Users/travis/Repos/kali-new/packages/gatsby/src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/travis/Repos/kali-new/packages/gatsby/.cache/data.json")

